
import { Component, Vue } from 'vue-property-decorator';
import Logo from '@/ui-components/Logo/Logo.vue'
import TextField from '@/ui-components/TextField/TextField.vue';
import MultiSelect from '@/ui-components/MultiSelect/MultiSelect.vue';
import IButton from '@/ui-components/IButton/IButton.vue';

@Component({
  name: 'SplitLayout',
  components: {
    IButton,
    MultiSelect,
    TextField,
    Logo
  },
})
export default class SplitLayout extends Vue {
}
